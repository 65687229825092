import * as React from 'react';
import { translate } from 'react-i18next';
import { withProviders } from '../../../contexts/main/withProviders';
import { withGeneralData } from '../../../contexts/GeneralDataProvider/withGeneralData';
import { withChallengeData } from '../../../contexts/ChallengeDataProvider/withChallengeData';
import { withLocation } from '../../../contexts/Location/withLocation';
import { IThankYouPageProps, IThankYouPageState } from './interfaces';
import { serverTimelineTypeToClientTimelineString } from '@wix/challenges-web-library/dist/src';
import { Pages } from '../../../contexts/Location/LocationContext';
import { getCountPrefix } from '../../../selectors/langCount';
import get from 'lodash/get';

import { Button, PRIORITY as ButtonPriority } from 'wix-ui-tpa/Button';

import styles from './Widget.st.css';
import { withInviteLink } from '../../../contexts/InviteLink/InviteLinkContext';
import { withBI } from '../../../contexts/BI/withBI';
import { ButtonNames } from '../../../contexts/BI/interfaces';
import { applyProviders } from '../../../services/applyProviders';
import { withUser } from '../../../contexts/User/withUser';
import { ParticipantState } from '@wix/ambassador-challenge-service-web/types';

class ThankYouPage extends React.Component<
  IThankYouPageProps,
  IThankYouPageState
> {
  static displayName = 'ThankYouPage';
  public readonly pageName = 'thank-you-page';

  goToChallengePage = async (challenge) => {
    await this.props.memberWebAppButtonClick({
      buttonName: ButtonNames.BackToChallenge,
    });

    this.props.goToPage({
      pageId: Pages.Details,
      challengeId: challenge.id,
    });
  };

  render() {
    const {
      t,
      lng,
      formFactor,
      challengeData: { challenge },
      inviteLink,
      ...rest
    } = this.props;
    const isMobile = formFactor === 'Mobile';

    const isJoined = this.props.userType !== ParticipantState.JOIN_REQUESTED;

    const langPrefix = getCountPrefix(
      get(challenge.settings.timelineType, 'flexible.duration.value', 0),
      lng,
    );

    const durationString = serverTimelineTypeToClientTimelineString(
      challenge.settings.timelineType as any,
      lng,
      t,
      'challenge-card.duration-string.ongoing',

      `challenge-card.duration-string.flexible.days${langPrefix}`,

      `challenge-card.duration-string.flexible.weeks${langPrefix}`,
    );

    return (
      <main
        data-hook={this.pageName}
        {...styles(
          'root',
          {
            mobile: isMobile,
          },
          rest,
        )}
      >
        <div className={styles.wrapper}>
          <h2 className={styles.title} data-hook={`${this.pageName}-title`}>
            {isJoined
              ? t(`thank-you.page.title`)
              : t(`thank-you.page.secret.title`)}
          </h2>
          <p
            className={styles.description}
            data-hook={`${this.pageName}-description`}
          >
            {isJoined
              ? t(`thank-you.page.description`)
              : t(`thank-you.page.secret.description`)}
          </p>

          <div className={styles.contentBox}>
            <h3
              className={styles.contentTitle}
              data-hook={`${this.pageName}-content-title`}
            >
              {challenge.settings.description.title}
            </h3>

            <p
              className={styles.text}
              data-hook={`${this.pageName}-text-summary`}
            >
              {durationString} ·{' '}
              {t(
                `thank-you.page.info.steps${getCountPrefix(
                  get(challenge, 'stepsSummary.stepsNumber', 0),
                  lng,
                )}`,
                {
                  count: get(challenge, 'stepsSummary.stepsNumber', 0),
                },
              )}
            </p>

            {isJoined ? (
              <p
                className={styles.text}
                data-hook={`${this.pageName}-text-details-link`}
              >
                <a
                  href={inviteLink}
                  {...{
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  }}
                  className={styles.link}
                >
                  {t('thank-you.page.view-in-app')}
                </a>
              </p>
            ) : (
              ''
            )}

            <Button
              priority={ButtonPriority.primary}
              className={styles.ctaButton}
              data-hook={`${this.pageName}-cta`}
              fullWidth={false}
              onClick={async (e) => {
                await this.goToChallengePage(challenge);

                e && e.preventDefault();
              }}
            >
              {t(
                isJoined ? 'thank-you.page.back' : 'thank-you.page.return.cta',
              )}
            </Button>
          </div>
        </div>
      </main>
    );
  }
}

export default applyProviders(ThankYouPage, [
  withProviders,
  withBI,
  withInviteLink,
  withLocation,
  withGeneralData,
  withChallengeData,
  withUser,
  translate(),
]);
